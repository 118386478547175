import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagramAllModule, SymbolPaletteModule } from '@syncfusion/ej2-angular-diagrams';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { ToolbarAllModule } from '@syncfusion/ej2-angular-navigations';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ToolbarAllModule
  ],
  exports: [
    DiagramAllModule,
    SymbolPaletteModule,
    UploaderModule,
    ToolbarAllModule
  ]
})
export class SharedModule { }
