import { MicrosoftLoginGuard } from '../microsoft-login.guard';
import { MainLayoutComponent } from '../layouts/main/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { InOperationComponent } from '../shared/components/bp/bp-in-operation/bp-in-operation.component';
import { DetailsLayoutComponent } from '../shared/components/bp/details/bp-details-layout.component';
import { EntryPageComponent } from '../shared/components/bp/details/detail-items/entry-page-processes.component';
import { MsalGuard } from '@azure/msal-angular';
import { SecurityOfProcessingComponent } from '../shared/components/bp/details/detail-items/security-of-processing/security-of-processing.component';
import { RiskAnylysisComponent } from '../shared/components/bp/details/detail-items/security-of-processing/risk-analysis/risk-analysis.component';
import { EntrySecurityOfProcessingComponent } from '../shared/components/bp/details/detail-items/security-of-processing/entry-security.component';
import { GeneralInfoComponent } from '../shared/components/bp/details/detail-items/general-info/general-info.component';
import { GdprFullAssessmentComponent } from '../shared/components/bp/details/detail-items/security-of-processing/risk-assessment/gdpr-full-risk.component';
import { ITFullAssessmentComponent } from '../shared/components/bp/details/detail-items/security-of-processing/risk-assessment/it-full-risk.component';
import { StandardControlSpecificationComponent } from '../shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/modals/standard-control-specification.component';
import { DataTransfersLayoutComponent } from '../shared/components/bp/details/detail-items/data-transfers/data-transfers-layout.component';
import { DataTransfersFormComponent } from '../shared/components/bp/details/detail-items/data-transfers/data-transfers-form.component';
import { DataTransfersGridComponent } from '../shared/components/bp/details/detail-items/data-transfers/data-transfers-grid';
import { TransferImpactAssessmentComponent } from '../shared/components/bp/tia/transfer-impact-assessment.component';
import { FullThreatComponent } from '../shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/full-threat.component';
import { ModelImportComponent } from '../shared/components/admin/sections/import/model_import.component';
import { AdministrationComponent } from '../shared/components/admin/sections/administration/administration.component';
import { ControlReportComponent } from '../shared/components/admin/sections/control-reports/control_report.component';
import { DpoReportComponent } from '../shared/components/admin/sections/dpo_reports/dpo_report.component';
import { LegalBasisComponent } from '../shared/components/bp/details/detail-items/legal-basis/legal-basis.component';
import { ComplianceComponent } from '../shared/components/bp/details/detail-items/compliance-gdpr-principles/compliance-with-gdpr-principles.component';
import { AuthGuard } from '../AuthGuard';
import { RightsOfDataSubjectsComponent } from '../shared/components/bp/details/detail-items/rights-of-data-subjects/rights-of-data-subjects.component';
import { AccessDeniedComponent } from '../shared/components/errors/access-denied.component';
import { ManagePermissionsLayoutComponent } from '../shared/components/admin/sections/manage-permissions/manage-permissions-layout.component';
import { DashboardComponent } from '../shared/components/dashboard/dashboard.component';
import { BasicInfoComponent } from '../shared/components/direct-menu-pages/basic-info.component';
import { BusinessFunctionsComponent } from '../shared/components/direct-menu-pages/business-functions/business-functions.component';
import { IsRuleBookComponent } from '../shared/components/direct-menu-pages/is-rule-book/rule-book.component';
import { LicensedActivityComponent } from '../shared/components/direct-menu-pages/modals/licensed-activities.component';
import { AdminRuleBookComponent } from '../shared/components/admin/data-management/admin-rule-book/admin-rule-book.component';
import { MyRuleBookComponent } from '../shared/components/direct-menu-pages/my-rule-book/my-rule-book.component';
import { EstablishBaselineComponent } from '../shared/components/direct-menu-pages/my-rule-book/sub-pages/establish-baseline.component';
import { AdminFunctionsComponent } from '../shared/components/admin/data-management/admin-functions/admin-functions.component';
import { InitialActionPlanComponent } from '../shared/components/header-menu/action-plan/initial-action-plan.component';
import { GanttChartComponent } from '../shared/components/header-menu/gannt-chart/gantt-chart.component';
import { ThreatsComponent } from '../shared/components/admin/sections/threats/threats.component';
import { OrganizationThreatsComponent } from '../shared/components/organization-admin/threats/threats.component';
import { TechnicalVulnerabilitiesComponent } from '../shared/components/admin/sections/technical-vulnerabilities/technical-vulnerabilities.component';

const homeRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  }
];


const RiskAssesmentRoutes: Routes = [
  {
    path: 'entry-security',
    component: EntrySecurityOfProcessingComponent,
    children: [{
      path: 'full-threat',
      component: FullThreatComponent,
      data: { preload: true }
    }]
  },
  {
    path: 'riskanalysis/:id',
    component: RiskAnylysisComponent
  },
  {
    path: 'gdpr-full',
    component: GdprFullAssessmentComponent
  },
  {
    path: 'it-full',
    component: ITFullAssessmentComponent
  }
];

const BPDetailsRoutes: Routes = [
  {
    path: 'security',
    component: SecurityOfProcessingComponent,
    children: RiskAssesmentRoutes
  },
  {
    path: 'general-info/:id',
    component: GeneralInfoComponent
  },
  {
    path: 'compliance-with-gdpr-principles/:id',
    component: ComplianceComponent
  },
  {
    path: 'rights-of-data-subjects/:id',
    component: RightsOfDataSubjectsComponent
  },
  {
    path: 'legal-basis/:id',
    component: LegalBasisComponent
  },
  {
    path: 'data-transfers-layout/:id',
    component: DataTransfersLayoutComponent,
    children: [
      {
        path: 'data-transfers-grid',
        component: DataTransfersGridComponent,
      },
      {
        path: 'data-transfers-form/:recipientId',
        component: DataTransfersFormComponent,
      }
    ]
  }
];

const routes: Routes = [
  {
    path: 'main-menu',
    component: MainLayoutComponent
  },
  {
    path: 'entry/:id',
    component: EntryPageComponent
  },
  {
    path: 'bp-details/:id',
    component: DetailsLayoutComponent,
    children: BPDetailsRoutes
  },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [
      MsalGuard
    ],
    children: homeRoutes
  },
  {
    path: 'bp',
    component: InOperationComponent
  },
  {
    path: 'organization-basic-info',
    component: BasicInfoComponent,
    canActivate: [
      MsalGuard
    ],
  },
  {
    path: 'business-functions',
    component: BusinessFunctionsComponent,
    canActivate: [
      MsalGuard
    ],
  },
  {
    path: 'is-rule-book',
    component: IsRuleBookComponent,
    canActivate: [
      MsalGuard
    ],
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'transfer-impact-assessment',
    component: TransferImpactAssessmentComponent
  },
  {
    path: 'administration',
    component: AdministrationComponent
  },
  {
    path: 'manage-permissions',
    component: ManagePermissionsLayoutComponent,
  },
  {
    path: 'admin-rule-book',
    component: AdminRuleBookComponent,
    canActivate: [MsalGuard],
  },
  {
    path:'admin-functions',
    component: AdminFunctionsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'my-rule-book',
    component: MyRuleBookComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'my-rule-book/establish-baseline/:subRuleId',
    component: EstablishBaselineComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'initial-action-plan',
    component: InitialActionPlanComponent,
    canActivate: [MsalGuard],
    data: {
      roles: ['isRequestManager', 'isComplianceManager', 'isDataController', 'isIncidentManager', 'isProcessOwnerIT']
    }
  },
  {
    path: 'initial-action-plan/gantt',
    component: GanttChartComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'control_report',
    component: ControlReportComponent
  },
  {
    path: 'dpo_report',
    component: DpoReportComponent
  },
  {
    path: 'model-import',
    component: ModelImportComponent
  },

  {
    path: 'admin-threats',
    component: ThreatsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'technical-vulnerabilities',
    component: TechnicalVulnerabilitiesComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'threats',
    component: OrganizationThreatsComponent,
    canActivate: [MsalGuard]
  },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }