import { Component, ViewEncapsulation, ViewChild, AfterViewChecked } from '@angular/core';
import {
  DiagramComponent, OrthogonalSegmentModel, PaletteModel,
  SnapSettingsModel,
  Diagram, NodeModel, UndoRedo, ConnectorModel, DiagramContextMenu, StrokeStyleModel,
  SymbolInfo, PointPortModel,

  IDragEnterEventArgs,
  IExportOptions,
  NodeConstraints,
  ConnectorConstraints,
  DiagramTools,
  ZoomOptions,
  IHistoryChangeArgs,
  ISelectionChangeEventArgs,
  Connector,
  FlowShapeModel,
  TextStyleModel,
  ImageElement,
  Segments
} from '@syncfusion/ej2-angular-diagrams';
import { ClickEventArgs, ExpandMode, MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-navigations';
import { MarginModel } from '@syncfusion/ej2-lineargauge';
import { ToolbarComponent } from '@syncfusion/ej2-angular-navigations';
import { ItemModel } from '@syncfusion/ej2-angular-splitbuttons';
import { AsyncSettingsModel } from '@syncfusion/ej2-angular-inputs';
import { paletteIconClick } from './scripts/diagram.common';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { HttpClient } from '@angular/common/http';
import { AdministrationService } from 'src/app/shared/services/admin.service';


Diagram.Inject(UndoRedo, DiagramContextMenu);

@Component({
  selector: 'diagram',
  templateUrl: 'diagram.component.html',
  styleUrls: ['diagram.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})

export class ArchimateComponent implements AfterViewChecked {
  @ViewChild('diagram') diagram!: DiagramComponent; // Use '!' to avoid strict type errors
  diagramInitialized = false; // To prevent multiple calls

  ngAfterViewChecked() {
    if (this.diagram && !this.diagramInitialized) {
      console.log('Diagram initialized:', this.diagram);
      this.diagramInitialized = true; // Prevent repeated calls
    }
  }

  @ViewChild('toolbar')
  public toolbar: ToolbarComponent;

  @ViewChild('propertiesDialog')
  public PropertiesDialog: DialogComponent;

  public dialogHeight = '40%';
  public dialogWidth = '50%';
  public dialogdragging = true;
  public showCloseIcon = true;
  public dialogResize = true;
  constructor(private service: AdministrationService) {

  }
  public menuItems: MenuItemModel[] = [
    {
      text: 'Main',
      url: '/main'
    },
    {
      text: 'Properties',
      url: '/properties'
    },
    {
      text: 'Analysis',
    },
    {
      text: 'Appearance',
      url: '/appearance'
    },
    {
      text: 'Label',
      url: '/label'
    },
    {
      text: 'Figure',
    },
    {
      text: 'Image',
      url: 'https://ej2.syncfusion.com/home/'
    }
  ];
  public enableDock: boolean = false;
  jsonData: any;
  public dockSize: string = '50px';
  public width: string = '220px';
  public target: string = '.main-menu-content';
  public terminator: FlowShapeModel = { type: 'Flow', shape: 'Terminator' };
  public process: FlowShapeModel = { type: 'Flow', shape: 'Process' };
  public decision: FlowShapeModel = { type: 'Flow', shape: 'Decision' };
  public data: FlowShapeModel = { type: 'Flow', shape: 'Data' };
  public directdata: FlowShapeModel = { type: 'Flow', shape: 'DirectData' };

  public margin: MarginModel = { left: 25, right: 25 };
  public connAnnotStyle: TextStyleModel = { fill: 'white' };
  public strokeStyle: StrokeStyleModel = { strokeDashArray: '2,2' };

  public segments: OrthogonalSegmentModel = [{ type: 'Orthogonal', direction: 'Top', length: 120 }];
  public segments1: OrthogonalSegmentModel = [
    { type: 'Orthogonal', direction: 'Right', length: 100 }
  ];
  public drawingObject: any;
  public asyncSettings: AsyncSettingsModel = {
    saveUrl: 'https://services.syncfusion.com/angular/production/api/FileUploader/Save',
    removeUrl: 'https://services.syncfusion.com/angular/production/api/FileUploader/Remove'
  };
  //Sets the default values of a node
  public nodeDefaults(node: NodeModel): NodeModel {
    if (node.width === undefined) {
      node.width = 145;
    }
    node.style = {
      // fill: 'blue', 
      strokeColor: '#757575'
    };
    for (let i: number = 0; i < node.annotations.length; i++) {
      node.annotations[i].style = {
        color: 'black',
        fill: 'transparent',
      };
    }
    if (node.shape && node.shape.type === 'Image') {
      node.style.strokeWidth = 0;
      // node.width = 40; node.height = 40;
    }
    node.ports = getPorts(node);
    return node;
  }

  //Sets the default values of a connector
  public connectorDefaults(obj: Connector): void {
    if (obj.id.indexOf('connector') !== -1) {
      obj.targetDecorator = { shape: 'Arrow', width: 10, height: 10 };
    }
  }

  public created(): void {
    this.diagram.fitToPage();
  }
  public interval: number[] = [
    1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25,
    9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75
  ];

  public selectedNode: any = null;
  public selectedMenu: string | null = null;
  public typeName: string | null = null;

  public onNodeClick(event: any): void {
    this.selectedNode = event.element?.properties;
    this.typeName = event.element?.properties?.annotations?.[0]?.properties?.content
      ? `${event.element.properties.annotations[0].properties.content} (${event.element.properties.addInfo?.type ?? ''})`
      : '';
    // this.PropertiesDialog.header = `<div>Properties</div>`
    // this.PropertiesDialog.show();
  }


  public onMenuSelect(args: MenuEventArgs): void {
    const selectedItem = this.menuItems.find(item => item.text === args.item.text);
    if (selectedItem?.url) {
      this.selectedMenu = selectedItem.text ?? null; // Store the menu selection
      args.event?.preventDefault();
    }
  }

  public snapSettings: SnapSettingsModel = {
    horizontalGridlines: { lineColor: '#e0e0e0', lineIntervals: this.interval },
    verticalGridlines: { lineColor: '#e0e0e0', lineIntervals: this.interval }
  };
  //Sets the Node style for DragEnter element.
  public dragEnter(args: IDragEnterEventArgs): void {
    let obj: NodeModel = args.element as NodeModel;
    if (obj && obj.width && obj.height) {
      let objWidth: number = obj.width;
      let objHeight: number = obj.height;
      let ratio: number = 100 / obj.width;
      obj.width = 100;
      obj.height *= ratio;
      obj.offsetX += (obj.width - objWidth) / 2;
      obj.offsetY += (obj.height - objHeight) / 2;
      obj.style = { fill: 'none', strokeColor: 'black' };
      obj.annotations = [{
        content: obj.addInfo['type']
      }]
    }
    obj.id = crypto.randomUUID();
  }

  //SymbolPalette Properties
  public symbolMargin: MarginModel = { left: 5, right: 5, top: 5, bottom: 5 };
  public expandMode: ExpandMode = 'Multiple';

  //Function to get flowshapes.
  public getFlowShape(id: string, shapeType: any): NodeModel {
    let flowshape: NodeModel = { id: id, shape: { type: 'Flow', shape: shapeType } };
    return flowshape;
  }

  private applyConnectorStyle(dashedLine: boolean, sourceDecorator: boolean, isRounded: boolean, type: Segments, strokeWidth?: number): void {
    for (let i: number = 0; i < this.diagram.connectors.length; i++) {
      this.diagram.connectors[i].style.strokeWidth = strokeWidth ? strokeWidth : 2;
      this.diagram.connectors[i].type = type;
      this.diagram.connectors[i].cornerRadius = isRounded ? 5 : 0;
      this.diagram.connectors[i].style.strokeDashArray = dashedLine ? '5,5' : '';
      if (sourceDecorator) {
        this.diagram.connectors[i].sourceDecorator = {
          style: {
            strokeColor: this.diagram.connectors[i].style.strokeColor,
            fill: this.diagram.connectors[i].style.strokeColor, strokeWidth: 2
          }, shape: 'Circle'
        };
        (document.getElementById('sourceDecorator') as any).ej2_instances[0].value = 'Circle';
      } else {
        this.diagram.connectors[i].sourceDecorator = { shape: 'None' };
        (document.getElementById('sourceDecorator') as any).ej2_instances[0].value = 'None';
      }
      this.diagram.connectors[i].targetDecorator = {
        style: {
          strokeColor: this.diagram.connectors[i].style.strokeColor,
          fill: this.diagram.connectors[i].style.strokeColor, strokeWidth: 2
        }, shape: 'Arrow'
      };
      (document.getElementById('targetDecorator') as any).ej2_instances[0].value = 'Arrow';
      this.diagram.dataBind();
      this.diagram.updateSelector();
    }
  }

  // Function to create connector symbols
  private createConnectorSymbol(id: string, type: any, targetDecoratorShape: any, strokeColor: string = '#757575', addInfo: any): ConnectorModel {
    return {
      id: id,
      type: type,
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 60, y: 60 },
      style: { strokeWidth: 1, strokeColor: strokeColor },
      targetDecorator: { shape: targetDecoratorShape, style: { strokeColor: strokeColor, fill: strokeColor } },
      addInfo: addInfo
    };
  }

  // Initializes connector symbols for the symbol palette
  private connectorSymbols: ConnectorModel[] = [
    {
      id: 'Composition',
      sourcePoint: { x: 100, y: 200 },
      targetPoint: { x: 200, y: 300 },
      type: 'Straight',
      sourceDecorator: {
        style: {
          strokeColor: 'black',
        },
        shape: 'Diamond'
      },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        },
        height: 6,
        width: 6,
        shape: 'Diamond'
      },
      addInfo: {
        type: 'Composition relation'
      }
    },
    {
      id: 'Aggregation',
      sourcePoint: { x: 100, y: 200 },
      targetPoint: { x: 200, y: 300 },
      type: 'Straight',
      sourceDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        },
        shape: 'Diamond'
      },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        },
        height: 6,
        width: 6,
        shape: 'Diamond'
      },
      addInfo: {
        type: 'Aggregation relation'
      }
    },
    {
      id: 'Assignment',
      type: 'Straight',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      sourceDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'black', strokeWidth: 2
        }, shape: 'Circle',
        height: 6,
        width: 6,
      },
      addInfo: {
        type: 'Assignment relation'
      },
      style: { strokeDashArray: '' }
    },
    {
      id: 'Realization',
      type: 'Straight',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'Arrow',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '1,5' },
      addInfo: {
        type: 'Realization relation'
      },
    },
    {
      id: 'RealizationOrthogonal',
      type: 'Orthogonal',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'Arrow',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '1,5' },
      addInfo: {
        type: 'Realization relation'
      },
    },
    {
      id: 'Serving',
      type: 'Straight',
      targetPoint: { x: 300, y: 400 },
      sourcePoint: { x: 400, y: 500 },
      style: { strokeDashArray: '' },
      sourceDecorator: {
        style: {
          strokeColor: 'black',
        },
        shape: 'OpenArrow'
      },
      targetDecorator: {
        style: {
          strokeColor: 'black',
        },
        shape: 'None'
      },
      addInfo: {
        type: 'Serving relation'
      },
    },
    {
      id: 'ServingOrthogonal',
      type: 'Orthogonal',
      targetPoint: { x: 300, y: 400 },
      sourcePoint: { x: 400, y: 500 },
      style: { strokeDashArray: '' },
      sourceDecorator: {
        style: {
          strokeColor: 'black',
        },
        shape: 'OpenArrow'
      },
      targetDecorator: {
        style: {
          strokeColor: 'black',
        },
        shape: 'None'
      },
      addInfo: {
        type: 'Serving relation'
      }
    },
    {
      id: 'AccessRead',
      type: 'Straight',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'OpenArrow',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '1,5' },
      addInfo: {
        type: 'Access relation'
      }
    },
    {
      id: 'AccessWrite',
      type: 'Straight',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      sourceDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'OpenArrow',
        height: 8,
        width: 8,
      },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'None',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '1,5' },
      addInfo: {
        type: 'Access relation'
      }
    },
    {
      id: 'AccessRead/Write',
      type: 'Straight',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'OpenArrow',
        height: 8,
        width: 8,
      },
      sourceDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'OpenArrow',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '1,5' },
      addInfo: {
        type: 'Access relation'
      }
    },
    {
      id: 'AccessNone',
      type: 'Straight',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'None',
        height: 8,
        width: 8,
      },
      sourceDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'None',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '1,5' },
      addInfo: {
        type: 'Access relation'
      }
    },
    this.createConnectorSymbol('Triggering', 'Straight', 'Arrow', '#757575', { type: 'Triggering relation' }),
    {
      id: 'Flow',
      type: 'Straight',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'black',
        }, shape: 'Arrow',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '5,5' },
      addInfo: {
        type: 'Flow relation'
      }
    },
    {
      id: 'FlowOrthogonal',
      type: 'Orthogonal',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'black',
        }, shape: 'Arrow',
        height: 8,
        width: 8,
      },
      addInfo: {
        type: 'Flow relation'
      },
      style: { strokeDashArray: '5,5' }
    },
    {
      id: 'Specialization',
      type: 'Freehand',
      sourcePoint: { x: 300, y: 400 },
      targetPoint: { x: 400, y: 500 },
      targetDecorator: {
        style: {
          strokeColor: 'black',
          fill: 'transparent',
        }, shape: 'Arrow',
        height: 8,
        width: 8,
      },
      style: { strokeDashArray: '' },
      addInfo: {
        type: 'Specialization relation'
      },
    },
    this.createConnectorSymbol('Association', 'Straight', 'None', '#757575', {
      type: 'Association relation'
    }),
    this.createConnectorSymbol('AssociationOrthogonal', 'Orthogonal', 'None', '#757575', {
      type: 'Association relation'
    }),

  ];
  public GroupingNodes: NodeModel[] = [
    {
      id: 'grouping', shape: {
        type: 'Image',
        source: '/images/full/grouping.svg'
      },
      addInfo: {
        type: 'Grouping'
      }
    },
    {
      id: 'location', shape: {
        type: 'Image',
        source: '/images/full/location.svg'
      },
      addInfo: {
        type: 'Location'
      }
    },
    {
      id: 'location_icon', shape: {
        type: 'Image',
        source: '/images/full/b_location.svg'
      },
      addInfo: {
        type: 'Location'
      }
    },
  ];

  public EquipmentNodes: NodeModel[] = [
    {
      id: 'equipment_icon', shape: {
        type: 'Image',
        source: '/images/full/equipment.svg'
      },
      addInfo: {
        type: 'Equipment'
      }
    },
    {
      id: 'equipment_icon', shape: {
        type: 'Image',
        source: '/images/full/b_equipment.svg'
      },
      addInfo: {
        type: 'Equipment'
      }
    },
    {
      id: 'facility_icon', shape: {
        type: 'Image',
        source: '/images/full/facility.svg'
      },
      addInfo: {
        type: 'Facility'
      }
    },
    {
      id: 'facility_icon', shape: {
        type: 'Image',
        source: '/images/full/b_facility.svg'
      },
      addInfo: {
        type: 'Facility'
      }
    },
    {
      id: 'distribution_icon', shape: {
        type: 'Image',
        source: '/images/full/distribution-network.svg'
      },
      addInfo: {
        type: 'Distribution Network'
      }
    },
    {
      id: 'distribution_icon', shape: {
        type: 'Image',
        source: '/images/full/b_distribution-network.svg'
      },
      addInfo: {
        type: 'Distribution Network'
      }
    },
    {
      id: 'material_icon', shape: {
        type: 'Image',
        source: '/images/full/material.svg'
      },
      addInfo: {
        type: 'Material'
      }
    },
    {
      id: 'material_icon', shape: {
        type: 'Image',
        source: '/images/full/b_material.svg'
      },
      addInfo: {
        type: 'Material'
      }
    },
  ];

  public bpShape: ImageElement = {
    id: 'business-process',
    type: 'Image',
    source: '/images/full/b_business-process.svg',
  } as unknown as ImageElement;

  public brShape: ImageElement = {
    id: 'business-role',
    type: 'Image',
    source: '/images/full/b_business-role.svg'
  } as unknown as ImageElement;

  public actorShape: ImageElement = {
    id: 'actor',
    type: 'Image',
    source: '/images/full/b_business-actor.svg'
  } as unknown as ImageElement;

  public groupingShape: ImageElement = {
    id: 'grouping',
    type: 'Image',
    source: '/images/full/grouping.svg'
  } as unknown as ImageElement;

  public applicationShape: ImageElement = {
    id: 'application',
    type: 'Image',
    source: '/images/full/application.svg'
  } as unknown as ImageElement;

  public dataObjectShape: ImageElement = {
    id: 'application',
    type: 'Image',
    source: '/images/full/dataObject.svg'
  } as unknown as ImageElement;


  public businessObjectShape: ImageElement = {
    id: 'application',
    type: 'Image',
    source: '/images/full/busines_object.png'
  } as unknown as ImageElement;

  public businessRoleShape: ImageElement = {
    id: 'application',
    type: 'Image',
    source: '/images/full/business_role.svg'
  } as unknown as ImageElement;

  public TechnologyNodes: NodeModel[] = [
    {
      id: 'node_icon', shape: {
        type: 'Image',
        source: '/images/full/node.svg'
      },
      addInfo: {
        type: 'Node'
      }
    },
    {
      id: 'node', shape: {
        type: 'Image',
        source: '/images/full/b_node.svg'
      },
      addInfo: {
        type: 'Node'
      }
    },
    {
      id: 'device_icon', shape: {
        type: 'Image',
        source: '/images/full/device.svg'
      },
      addInfo: {
        type: 'Device'
      }
    },
    {
      id: 'device', shape: {
        type: 'Image',
        source: '/images/full/b_device.svg'
      },
      addInfo: {
        type: 'Device'
      }
    },
    {
      id: 'system_software_icon', shape: {
        type: 'Image',
        source: '/images/full/system-software.svg'
      },
      addInfo: {
        type: 'System Software'
      }
    },
    {
      id: 'system_software', shape: {
        type: 'Image',
        source: '/images/full/b_system-software.svg'
      },
      addInfo: {
        type: 'System Software'
      }
    },
    {
      id: 'technology_collaboration_icon', shape: {
        type: 'Image',
        source: '/images/full/technology-collaboration.svg'
      },
      addInfo: {
        type: 'Technology Collaboration'
      }
    },
    {
      id: 'technology_collaboration', shape: {
        type: 'Image',
        source: '/images/full/b_technology-collaboration.svg'
      },
      addInfo: {
        type: 'Technology Collaboration'
      }
    },
    {
      id: 'technology_interface_icon', shape: {
        type: 'Image',
        source: '/images/full/technology-interface.svg'
      },
      addInfo: {
        type: 'Technology Interface'
      }
    },
    {
      id: 'technology_interface', shape: {
        type: 'Image',
        source: '/images/full/b_technology-interface.svg'
      },
      addInfo: {
        type: 'Technology Interface'
      }
    },
    {
      id: 'path_icon', shape: {
        type: 'Image',
        source: '/images/full/path.svg'
      },
      addInfo: {
        type: 'Path'
      }
    },
    {
      id: 'path', shape: {
        type: 'Image',
        source: '/images/full/b_path.svg'
      },
      addInfo: {
        type: 'Path'
      }
    },
    {
      id: 'communication_icon', shape: {
        type: 'Image',
        source: '/images/full/communication-network.svg'
      },
      addInfo: {
        type: 'Communication Network'
      }
    },
    {
      id: 'communication', shape: {
        type: 'Image',
        source: '/images/full/b_communication-network.svg'
      },
      addInfo: {
        type: 'Communication Network'
      }
    },
    {
      id: 'technology_function_icon', shape: {
        type: 'Image',
        source: '/images/full/technology-function.svg'
      },
      addInfo: {
        type: 'Technology Function'
      }
    },
    {
      id: 'technology_function', shape: {
        type: 'Image',
        source: '/images/full/b_technology-function.svg'
      },
      addInfo: {
        type: 'Technology Function'
      }
    },
    {
      id: 'technology_process_icon', shape: {
        type: 'Image',
        source: '/images/full/technology-process.svg'
      },
      addInfo: {
        type: 'Technology Process'
      }
    },
    {
      id: 'technology_process', shape: {
        type: 'Image',
        source: '/images/full/b_technology-process.svg'
      },
      addInfo: {
        type: 'Technology Process'
      }
    },
    {
      id: 'technology_interaction_icon', shape: {
        type: 'Image',
        source: '/images/full/technology-interaction.svg'
      },
      addInfo: {
        type: 'Technology Interaction'
      }
    },
    {
      id: 'technology_interaction', shape: {
        type: 'Image',
        source: '/images/full/b_technology-interaction.svg'
      },
      addInfo: {
        type: 'Technology Interaction'
      }
    },
    {
      id: 'technology_event_icon', shape: {
        type: 'Image',
        source: '/images/full/technology-event.svg'
      },
      addInfo: {
        type: 'Technology Event'
      }
    },
    {
      id: 'technology_event', shape: {
        type: 'Image',
        source: '/images/full/b_technology-event.svg'
      },
      addInfo: {
        type: 'Technology Event'
      }
    },
    {
      id: 'technology_service_icon', shape: {
        type: 'Image',
        source: '/images/full/technology-service.svg'
      },
      addInfo: {
        type: 'Technology Service'
      }
    },
    {
      id: 'technology_service', shape: {
        type: 'Image',
        source: '/images/full/b_technology-service.svg'
      },
      addInfo: {
        type: 'Technology Service'
      }
    },
    {
      id: 'technology_artifact_icon', shape: {
        type: 'Image',
        source: '/images/full/artifact.svg'
      },
      addInfo: {
        type: 'Artifact'
      }
    },
    {
      id: 'technology_artifact', shape: {
        type: 'Image',
        source: '/images/full/b_artifact.svg'
      },
      addInfo: {
        type: 'Artifact'
      }
    },
  ];

  public BusinessNodes: NodeModel[] = [
    {
      id: 'actor_icon', shape: {
        type: 'Image',
        source: '/images/full/actor.svg',
      },
      addInfo: {
        type: 'Business Actor'
      }
    },
    {
      id: 'actor', shape: {
        type: 'Image',
        source: '/images/full/b_business-actor.svg'
      },
      addInfo: {
        type: 'Business Actor'
      }
    },
    {
      id: 'business_role_icon', shape: {
        type: 'Image',
        source: '/images/full/business-role.svg'
      },
      addInfo: {
        type: 'Business Role'
      }
    },
    {
      id: 'business_role', shape: {
        type: 'Image',
        source: '/images/full/b_business-role.svg'
      },
      addInfo: {
        type: 'Business Role'
      }
    },
    {
      id: 'business_collaboration_icon', shape: {
        type: 'Image',
        source: '/images/full/business-collaboration.svg'
      },
      addInfo: {
        type: 'Business Collaboration'
      }
    },
    {
      id: 'business_collaboration', shape: {
        type: 'Image',
        source: '/images/full/b_business-collaboration.svg'
      },
      addInfo: {
        type: 'Business Collaboration'
      }
    },
    {
      id: 'business_interface_icon', shape: {
        type: 'Image',
        source: '/images/full/business-interface.svg'
      },
      addInfo: {
        type: 'Business Interface'
      }
    },
    {
      id: 'business_interface', shape: {
        type: 'Image',
        source: '/images/full/b_business-interface.svg'
      },
      addInfo: {
        type: 'Business Interface'
      }
    },
    {
      id: 'business_process_icon', shape: {
        type: 'Image',
        source: '/images/full/business-process.svg'
      },
      addInfo: {
        type: 'Business Process'
      }
    },
    {
      id: 'business_process', shape: {
        type: 'Image',
        source: '/images/full/b_business-process.svg'
      },
      addInfo: {
        type: 'Business Process'
      }
    },
    {
      id: 'business_function_icon', shape: {
        type: 'Image',
        source: '/images/full/business-function.svg'
      },
      addInfo: {
        type: 'Business Function'
      }
    },
    {
      id: 'business_function', shape: {
        type: 'Image',
        source: '/images/full/b_business_function.svg'
      },
      addInfo: {
        type: 'Business Function'
      }
    },
    {
      id: 'business_interaction_icon', shape: {
        type: 'Image',
        source: '/images/full/business-interaction.svg'
      },
      addInfo: {
        type: 'Business Interaction'
      }
    },
    {
      id: 'business_interaction', shape: {
        type: 'Image',
        source: '/images/full/b_business-interaction.svg'
      },
      addInfo: {
        type: 'Business Interaction'
      }
    },
    {
      id: 'business_event_icon', shape: {
        type: 'Image',
        source: '/images/full/business-event.svg'
      },
      addInfo: {
        type: 'Business Event'
      }
    },
    {
      id: 'business_event', shape: {
        type: 'Image',
        source: '/images/full/b_business-event.svg'
      },
      addInfo: {
        type: 'Business Event'
      }
    },
    {
      id: 'business_service_icon', shape: {
        type: 'Image',
        source: '/images/full/business-service.svg'
      },
      addInfo: {
        type: 'Business Service'
      }
    },
    {
      id: 'business_service', shape: {
        type: 'Image',
        source: '/images/full/b_business-service.svg'
      },
      addInfo: {
        type: 'Business Service'
      }
    },
    {
      id: 'business_object_icon', shape: {
        type: 'Image',
        source: '/images/full/business-object.svg'
      },
      addInfo: {
        type: 'Business Object'
      }
    },
    {
      id: 'business_object', shape: {
        type: 'Image',
        source: '/images/full/b_business-object.svg'
      },
      addInfo: {
        type: 'Business Object'
      }
    },
    {
      id: 'business_contract_icon', shape: {
        type: 'Image',
        source: '/images/full/contract.svg'
      },
      addInfo: {
        type: 'Contract'
      }
    },
    {
      id: 'business_contract', shape: {
        type: 'Image',
        source: '/images/full/b_contract.svg'
      },
      addInfo: {
        type: 'Contract'
      }
    },

    {
      id: 'business_representation_icon', shape: {
        type: 'Image',
        source: '/images/full/representation.svg'
      },
      addInfo: {
        type: 'Representation'
      }
    },
    {
      id: 'business_representation', shape: {
        type: 'Image',
        source: '/images/full/b_representation.svg'
      },
      addInfo: {
        type: 'Representation'
      }
    },
    {
      id: 'business_product_icon', shape: {
        type: 'Image',
        source: '/images/full/product.svg'
      },
      addInfo: {
        type: 'Product'
      }
    },
    {
      id: 'business_product', shape: {
        type: 'Image',
        source: '/images/full/b_product.svg'
      },
      addInfo: {
        type: 'Product'
      }
    }
  ];

  public ApplicationNodes: NodeModel[] = [
    {
      id: 'application_icon', shape: {
        type: 'Image',
        source: '/images/full/application-component.svg'
      },
      addInfo: {
        type: 'Application Component'
      }
    },
    {
      id: 'application', shape: {
        type: 'Image',
        source: '/images/full/b_application-component.svg'
      },
      addInfo: {
        type: 'Application Component'
      }
    },
    {
      id: 'application_collaboration_icon', shape: {
        type: 'Image',
        source: '/images/full/application-collaboration.svg'
      },
      addInfo: {
        type: 'Application Collaboration'
      }
    },
    {
      id: 'application_collaboration', shape: {
        type: 'Image',
        source: '/images/full/b_application-collaboration.svg'
      },
      addInfo: {
        type: 'Application Collaboration'
      }
    },
    {
      id: 'application_interface_icon', shape: {
        type: 'Image',
        source: '/images/full/application-interface.svg'
      },
      addInfo: {
        type: 'Application Interface'
      }
    },
    {
      id: 'application_interface', shape: {
        type: 'Image',
        source: '/images/full/b_application-interface.svg'
      },
      addInfo: {
        type: 'Application Interface'
      }
    },
    {
      id: 'application_function_icon', shape: {
        type: 'Image',
        source: '/images/full/application-function.svg'
      },
      addInfo: {
        type: 'Application Function'
      }
    },
    {
      id: 'application_function', shape: {
        type: 'Image',
        source: '/images/full/b_application-function.svg'
      },
      addInfo: {
        type: 'Application Function'
      }
    },

    {
      id: 'application_interaction_icon', shape: {
        type: 'Image',
        source: '/images/full/application-interaction.svg'
      },
      addInfo: {
        type: 'Application Interaction'
      }
    },
    {
      id: 'application_interaction', shape: {
        type: 'Image',
        source: '/images/full/b_application-interaction.svg'
      },
      addInfo: {
        type: 'Application Interaction'
      }
    },
    {
      id: 'application_process_icon', shape: {
        type: 'Image',
        source: '/images/full/application-process.svg'
      },
      addInfo: {
        type: 'Application Process'
      }
    },
    {
      id: 'application_process', shape: {
        type: 'Image',
        source: '/images/full/b_application-process.svg'
      },
      addInfo: {
        type: 'Application Process'
      }
    },
    {
      id: 'application_event_icon', shape: {
        type: 'Image',
        source: '/images/full/application-event.svg'
      },
      addInfo: {
        type: 'Application Event'
      }
    },
    {
      id: 'application_event', shape: {
        type: 'Image',
        source: '/images/full/b_application-event.svg'
      },
      addInfo: {
        type: 'Application Event'
      }
    },
    {
      id: 'application_service_icon', shape: {
        type: 'Image',
        source: '/images/full/application-service.svg'
      },
      addInfo: {
        type: 'Application Service'
      }
    },
    {
      id: 'application_service', shape: {
        type: 'Image',
        source: '/images/full/b_application-service.svg'
      },
      addInfo: {
        type: 'Application Service'
      }
    },
    {
      id: 'data_object_icon', shape: {
        type: 'Image',
        source: '/images/full/data-object.svg'
      },
      addInfo: {
        type: 'Data Object'
      }
    },
    {
      id: 'data-object', shape: {
        type: 'Image',
        source: '/images/full/b_data-object.svg'
      },
      addInfo: {
        type: 'Data Object'
      }
    },
  ];
  //To Initialise Symbol palette
  public palettes: PaletteModel[] = [
    // {
    //   id: 'flow',
    //   expanded: true,
    //   symbols: this.flowshapes,
    //   iconCss: 'shapes',
    //   title: 'Flow Shapes'
    // },
    {
      id: 'connectors',
      expanded: true,
      symbols: this.connectorSymbols,
      iconCss: 'shapes',
      title: ''
    },
    { id: 'grouping', expanded: true, symbols: this.GroupingNodes, title: '' },
    { id: 'business', expanded: true, symbols: this.BusinessNodes, title: '' },
    { id: 'applications', expanded: true, symbols: this.ApplicationNodes, title: '' },
    { id: 'technology', expanded: true, symbols: this.TechnologyNodes, title: '' },
    { id: 'equipment', expanded: true, symbols: this.EquipmentNodes, title: '' },
  ];

  public getSymbolInfo(symbol: NodeModel): SymbolInfo {
    return { fit: true };
  }
  //To set Default values for symbol palette
  public getSymbolDefaults(symbol: NodeModel): void {
    symbol.style.strokeColor = '#757575';
    symbol.style.strokeWidth = 0;
    if (symbol.id === 'Terminator' || symbol.id === 'Process') {
      symbol.width = 80;
      symbol.height = 40;
    } else if (
      symbol.id === 'Decision' ||
      symbol.id === 'Document' ||
      symbol.id === 'PreDefinedProcess' ||
      symbol.id === 'PaperTap' ||
      symbol.id === 'DirectData' ||
      symbol.id === 'MultiDocument' ||
      symbol.id === 'Data'
    ) {
      symbol.width = 50;
      symbol.height = 40;
    } else {
      symbol.width = 50;
      symbol.height = 50;
    }
  }
  //To enable and disable the toolbar items based on selection.
  public selectionChange(args: ISelectionChangeEventArgs): void {
    if (args.state === 'Changed') {
      var selectedItems = this.diagram.selectedItems.nodes;
      selectedItems = selectedItems.concat(this.diagram.selectedItems.connectors as any);
      if (selectedItems.length === 0) {
        const itemIds = ['Cut', 'Copy', 'Lock', 'Delete', 'Order', 'Rotate', 'Flip'];
        itemIds.forEach(itemId => {
          const item = this.toolbar.items.find(item => item.id === itemId);
          if (item) {
            item.disabled = true;
          }
        });
        this.disableMultiselectedItems();
      }
      if (selectedItems.length === 1) {
        this.enableItems();
        this.disableMultiselectedItems();

        if (selectedItems[0].children !== undefined && selectedItems[0].children.length > 0) {
          var Index = this.toolbar.items.findIndex(item => item.id === 'Group');
          if (Index !== -1) {
            this.toolbar.items[Index].disabled = false;
          }
        }
        else {
          var Index = this.toolbar.items.findIndex(item => item.id === 'Group');
          if (Index !== -1) {
            this.toolbar.items[Index].disabled = true;
          }
        }

      }

      if (selectedItems.length > 1) {
        this.enableItems();
        const itemIds = ['Align_objects', 'Group'];
        itemIds.forEach(itemId => {
          const item = this.toolbar.items.find(item => item.id === itemId);
          if (item) {
            item.disabled = false;
          }
        });
        //To enable distribute objcets when selected items length is greater than 2
        if (selectedItems.length > 2) {
          var Index = this.toolbar.items.findIndex(item => item.id === 'Distribute_objects');
          if (Index !== -1) {
            this.toolbar.items[Index].disabled = false;
          }
        }
        else {
          var Index = this.toolbar.items.findIndex(item => item.id === 'Distribute_objects');
          if (Index !== -1) {
            this.toolbar.items[Index].disabled = true;
          }
        }
      }

    }
  }
  //To enable and disable undo/redo button.
  public historyChange(args: IHistoryChangeArgs): void {
    const undoItem = this.toolbar.items.find(item => item.id === 'Undo');
    if (undoItem) {
      undoItem.disabled = this.diagram.historyManager.undoStack.length > 0 ? false : true;
    }
    const redoItem = this.toolbar.items.find(item => item.id === 'Redo');
    if (redoItem) {
      redoItem.disabled = this.diagram.historyManager.redoStack.length > 0 ? false : true;
    }
  }
  // Function to update the toolbar state based on selected nodes constraints
  public updateToolbarState(isLocked) {
    const itemIds = ['Cut', 'Copy', 'Delete', 'Order', 'Rotate', 'Flip'];
    itemIds.forEach(itemId => {
      const item = this.toolbar.items.find(item => item.id === itemId);
      if (item) {
        item.disabled = isLocked;
      }
    });
    var Index = this.toolbar.items.findIndex(item => item.id === 'Lock');
    if (Index !== -1) {
      this.toolbar.items[Index].disabled = false;
    }

  }
  // To enable toolbar items
  public enableItems() {
    const itemIds = ['Cut', 'Copy', 'Lock', 'Delete', 'Order', 'Rotate', 'Flip'];
    itemIds.forEach(itemId => {
      const item = this.toolbar.items.find(item => item.id === itemId);
      if (item) {
        item.disabled = false;
      }
    });
  }
  //To disable toolbar items
  public disableMultiselectedItems() {
    const itemIds = ['Align_objects', 'Distribute_objects', 'Group'];
    itemIds.forEach(itemId => {
      const item = this.toolbar.items.find(item => item.id === itemId);
      if (item) {
        item.disabled = true;
      }
    });
  }
  // To handle toolbar click
  public clicked(args: ClickEventArgs) {
    var item = (args as any).item.tooltipText;
    switch (item) {
      case 'Undo':
        this.diagram.undo();
        break;
      case 'Redo':
        this.diagram.redo();
        break;
      case 'Lock':
        this.lockObject();
        break;
      case 'Cut':
        this.diagram.cut();
        var pasteIndex = this.toolbar.items.findIndex(item => item.id === 'Paste');
        if (pasteIndex !== -1) {
          this.toolbar.items[pasteIndex].disabled = false;
        }
        break;
      case 'Copy':
        this.diagram.copy();
        var pasteIndex = this.toolbar.items.findIndex(item => item.id === 'Paste');
        if (pasteIndex !== -1) {
          this.toolbar.items[pasteIndex].disabled = false;
        }
        break;
      case 'Paste':
        this.diagram.paste();
        break;
      case 'Delete':
        this.diagram.remove();
        break;
      case 'Select Tool':
        this.diagram.clearSelection();
        this.diagram.tool = DiagramTools.Default;
        break;
      case 'Text Tool':
        this.diagram.clearSelection();
        this.diagram.selectedItems.userHandles = [];
        this.diagram.drawingObject = { shape: { type: 'Text' }, };
        this.diagram.tool = DiagramTools.ContinuousDraw;
        break;
      case 'Pan Tool':
        this.diagram.clearSelection();
        this.diagram.tool = DiagramTools.ZoomPan;
        break;
      case 'New Diagram':
        this.diagram.clear();
        this.historyChange(args as any);
        break;
      case 'Print Diagram':
        this.printDiagram(args);
        break;
      case 'Save template':
        // this.download(this.diagram.saveDiagram());
        this.saveTemplateToBackend(this.diagram.saveDiagram());
        break;
      case 'Load from template':
        this.loadTemplate();
        //document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
        break;
    }
    this.diagram.dataBind();
  }

  //Icons for Zoom Items
  public zoomMenuItems = [
    { text: 'Zoom In' },
    { text: 'Zoom Out' }, { text: 'Zoom to Fit' },
    { text: 'Zoom to 50%' },
    { text: 'Zoom to 100%' },
    { text: 'Zoom to 200%' },
  ]

  // To perform zoom operation
  public zoomChange(args) {
    var currentZoom = this.diagram.scrollSettings.currentZoom;
    var zoom: ZoomOptions = {};
    switch (args.item.text) {
      case 'Zoom In':
        this.diagram.zoomTo({ type: 'ZoomIn', zoomFactor: 0.2 });
        break;
      case 'Zoom Out':
        this.diagram.zoomTo({ type: 'ZoomOut', zoomFactor: 0.2 });
        break;
      case 'Zoom to Fit':
        zoom.zoomFactor = 1 / currentZoom - 1;
        this.diagram.zoomTo(zoom);
        break;
      case 'Zoom to 50%':
        if (currentZoom === 0.5) {
          currentZoom = 0;
          zoom.zoomFactor = (0.5 / currentZoom) - 1;
          this.diagram.zoomTo(zoom);

        }
        else {
          zoom.zoomFactor = (0.5 / currentZoom) - 1;
          this.diagram.zoomTo(zoom);
        }
        break;

      case 'Zoom to 100%':
        if (currentZoom === 1) {
          currentZoom = 0;
          zoom.zoomFactor = (1 / currentZoom) - 1;
          this.diagram.zoomTo(zoom);
        }
        else {
          zoom.zoomFactor = (1 / currentZoom) - 1;
          this.diagram.zoomTo(zoom);
        }
        break;
      case 'Zoom to 200%':
        if (currentZoom === 2) {
          currentZoom = 0;
          zoom.zoomFactor = (2 / currentZoom) - 1;
          this.diagram.zoomTo(zoom);
        }
        else {
          zoom.zoomFactor = (2 / currentZoom) - 1;
          this.diagram.zoomTo(zoom);
        }
        break;
    }
  }
  //To handle selection of connectors.
  public onConnectorSelect(args: any) {
    debugger
    this.diagram.clearSelection();
    this.diagram.drawingObject = { type: args.item.text };
    this.diagram.tool = DiagramTools.ContinuousDraw;
    this.diagram.selectedItems.userHandles = [];
    this.diagram.dataBind();
  }
  //Connector Icons
  public conTypeItems = [
    { text: 'Straight', iconCss: 'e-icons e-line' },
    { text: 'Orthogonal', iconCss: 'sf-icon-orthogonal' },
    { text: 'Bezier', iconCss: 'sf-icon-bezier' }
  ];
  //Shape Icons
  public shapesItems = [
    { text: 'Rectangle', iconCss: 'e-rectangle e-icons' },
    { text: 'Ellipse', iconCss: ' e-circle e-icons' },
    { text: 'Polygon', iconCss: 'e-line e-icons' }
  ];
  //To handle selection of shapes.
  public onShapesSelect(args) {
    this.diagram.clearSelection();
    this.diagram.drawingObject = { shape: { shape: args.item.text } };
    this.diagram.tool = DiagramTools.ContinuousDraw;
    this.diagram.selectedItems.userHandles = [];
    this.diagram.dataBind();
  }
  //Icons to Group and ungroup
  public groupItems = [
    { text: 'Group', iconCss: 'e-icons e-group-1' },
    { text: 'Ungroup', iconCss: 'e-icons e-ungroup-1' }
  ];
  //Group and ungroup the diagraming object.
  public onSelectGroup(args) {
    if (args.item.text === 'Group') {
      this.diagram.group();
    }
    else if (args.item.text === 'Ungroup') {
      this.diagram.unGroup();
    }
  }
  //Icons to align items
  public alignItems = [
    { iconCss: 'sf-icon-align-left-1', text: 'Align Left' },
    { iconCss: 'sf-icon-align-center-1', text: 'Align Center' },
    { iconCss: 'sf-icon-align-right-1', text: 'Align Right' },
    { iconCss: 'sf-icon-align-top-1', text: 'Align Top' },
    { iconCss: 'sf-icon-align-middle-1', text: 'Align Middle' },
    { iconCss: 'sf-icon-align-bottom-1', text: 'Align Bottom' },
  ];
  //To align selelcted diagram objects.
  public onSelectAlignObjects(args) {
    var item = args.item.text;
    var alignType = item.replace('Align', '');
    var alignType1 = alignType.charAt(0).toUpperCase() + alignType.slice(1);
    this.diagram.align(alignType1.trim());
  }
  //Icon to distributeItems
  public distributeItems = [
    { iconCss: 'sf-icon-distribute-vertical', text: 'Distribute Objects Vertically', },
    { iconCss: 'sf-icon-distribute-horizontal', text: 'Distribute Objects Horizontally', },
  ];
  //To distribute selected objects horizontally and vertically.
  public onSelectDistributeObjects(args) {
    this.diagram.distribute(args.item.text === 'Distribute Objects Vertically' ? 'BottomToTop' : 'RightToLeft');
  }
  //OrderItem Icons
  public orderItems = [
    { iconCss: 'e-icons e-bring-forward', text: 'Bring Forward' },
    { iconCss: 'e-icons e-bring-to-front', text: 'Bring To Front' },
    { iconCss: 'e-icons e-send-backward', text: 'Send Backward' },
    { iconCss: 'e-icons e-send-to-back', text: 'Send To Back' }
  ];
  //To execute order commands
  public onSelectOrder(args) {
    switch (args.item.text) {
      case 'Bring Forward':
        this.diagram.moveForward();
        break;
      case 'Bring To Front':
        this.diagram.bringToFront();
        break;
      case 'Send Backward':
        this.diagram.sendBackward();
        break;
      case 'Send To Back':
        this.diagram.sendToBack();
        break;
    }
  }
  //Icons for rotate button
  public rotateItems = [
    { iconCss: 'e-icons e-transform-right', text: 'Rotate Clockwise' },
    { iconCss: 'e-icons e-transform-left', text: 'Rotate Counter-Clockwise' }
  ];

  //To rotate the selected objects.
  public onSelectRotate(args) {

    this.diagram.rotate(this.diagram.selectedItems, args.item.text === 'Rotate Clockwise' ? 90 : -90);
  }
  //Flip Icons
  public flipItems = [
    { iconCss: 'e-icons e-flip-horizontal', text: 'Flip Horizontal' },
    { iconCss: 'e-icons e-flip-vertical', text: 'Flip Vertical' }
  ];
  public onSelectFlip(args) {
    this.flipObjects(args.item.text);
  }

  // To flip diagram objects
  public flipObjects(flipType) {
    var selectedObjects = this.diagram.selectedItems.nodes.concat(this.diagram.selectedItems.connectors as any);
    for (let i: number = 0; i < selectedObjects.length; i++) {
      selectedObjects[i].flip = flipType === 'Flip Horizontal' ? 'Horizontal' : 'Vertical';
    }
    this.diagram.dataBind();
  }
  //To lock the selected diagram object.
  public lockObject() {
    let isChecked;
    for (let i: number = 0; i < this.diagram.selectedItems.nodes.length; i++) {
      let node = this.diagram.selectedItems.nodes[i];
      if (node.constraints & NodeConstraints.Drag) {
        node.constraints = NodeConstraints.PointerEvents | NodeConstraints.Select | NodeConstraints.ReadOnly;
        isChecked = true;
      } else {
        node.constraints = NodeConstraints.Default;
        isChecked = false;
      }
    }
    for (let j: number = 0; j < this.diagram.selectedItems.connectors.length; j++) {
      let connector = this.diagram.selectedItems.connectors[j];
      if (connector.constraints & ConnectorConstraints.Drag) {
        connector.constraints = ConnectorConstraints.PointerEvents | ConnectorConstraints.Select | ConnectorConstraints.ReadOnly;
        isChecked = true;
      } else {
        connector.constraints = ConnectorConstraints.Default;
        isChecked = false;
      }
    }
    this.updateToolbarState(isChecked);
    this.diagram.dataBind();
  }
  public zoomContent() {
    return Math.round(this.diagram.scrollSettings.currentZoom * 100) + ' %'
  };
  // Set up print options and initiate printing of the diagram.
  public printDiagram(args) {
    var options: IExportOptions = {};
    options.mode = 'Download';
    options.region = 'Content';
    options.multiplePage = this.diagram.pageSettings.multiplePage;
    options.pageHeight = this.diagram.pageSettings.height;
    options.pageWidth = this.diagram.pageSettings.width;
    this.diagram.print(options);
  }
  //Export the diagraming object based on the format.
  public onselectExport(args) {
    var exportOptions: IExportOptions = {};
    exportOptions.format = args.item.text;
    exportOptions.mode = 'Download';
    exportOptions.region = 'PageSettings';
    exportOptions.fileName = 'Export';
    exportOptions.margin = { left: 0, top: 0, bottom: 0, right: 0 };
    this.diagram.exportDiagram(exportOptions);
  }
  // To save the diagram.
  public download(data: string): void {
    if ((window.navigator as any).msSaveBlob) {
      let blob: Blob = new Blob([data], { type: 'data:text/json;charset=utf-8,' });
      (window.navigator as any).msSaveOrOpenBlob(blob, 'Diagram.json');
    } else {
      let dataStr: string = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
      let a: HTMLAnchorElement = document.createElement('a');
      a.href = dataStr;
      a.download = 'Template.json';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  public saveTemplateToBackend(data: any): void {
    const jsonData = JSON.stringify(data);  // Convert data to JSON string

    // Send JSON object in the body (no need for FormData now)
    this.service.updateTemplate(jsonData).subscribe(
      (response) => {
        console.log('Template saved successfully:', response);
      },
      (error) => {
        console.error('Error saving template:', error);
      }
    );
  }



  loadTemplate(): void {
    // Use HttpClient to fetch the JSON file
    this.service.loadFromtemplate().subscribe(
      (data) => {
        this.jsonData = data;
        console.log('Loaded JSON:', this.jsonData);
        this.diagram.loadDiagram(this.jsonData)
      },
      (error) => {
        console.error('Error loading JSON:', error);
      }
    );
  }

  //set up uploaded file and call loadDiagram
  public onUploadSuccess(args: { [key: string]: Object }): void {
    debugger
    let file1: { [key: string]: Object } = args.file as { [key: string]: Object };
    let file: Blob = file1.rawFile as Blob;
    let reader: FileReader = new FileReader();
    reader.readAsText(file);
    var parsedData = this;
    reader.onloadend = this.loadDiagram.bind(this);
  }
  //To load diagram 
  public loadDiagram(event: ProgressEvent): void {
    this.diagram.loadDiagram((event.target as FileReader).result.toString());
  }

  public items: ItemModel[] = [
    { text: 'JPG' }, { text: 'PNG' }, { text: 'SVG' }

  ];
  public addDisabled(args: MenuEventArgs) {
    this.onselectExport(args);
  }

  public diagramCreate(args: Object): void {
    paletteIconClick();
  }
}
//Create and add ports for node.
function getPorts(obj: NodeModel): PointPortModel[] {
  let ports: PointPortModel[] = [
    { id: 'port1', shape: 'Circle', offset: { x: 0, y: 0.5 } },
    { id: 'port2', shape: 'Circle', offset: { x: 0.5, y: 1 } },
    { id: 'port3', shape: 'Circle', offset: { x: 1, y: 0.5 } },
    { id: 'port4', shape: 'Circle', offset: { x: 0.5, y: 0 } }
  ];
  return ports;
}