import { Component, Input, ViewEncapsulation } from "@angular/core";
import { NodeModel } from "@syncfusion/ej2-angular-diagrams";
import { ArchimateComponent } from "../diagram/diagram.component";
let node: NodeModel;

@Component({
    selector: 'main',
    templateUrl: 'main-menu-properties.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class MainPropertiesItemComponent {
    @Input() properties: any;
    constructor(private parent: ArchimateComponent) { }
    fieldValueChanged(e: any) {
        this.applyAnnotationStylenotationStyle(e.target.value);
    }
    private applyAnnotationStylenotationStyle(propertyName: string): void {
        if (this.parent.diagram && this.parent.diagram.selectedItems && this.parent.diagram.selectedItems.nodes) {
            for (let i: number = 0; i < this.parent.diagram.selectedItems.nodes.length; i++) {
                if (this.parent.diagram.selectedItems.nodes[i]) {
                    node = this.parent.diagram.selectedItems.nodes[i];
                    if (node.annotations && node.annotations.length > 0) {
                        node.annotations[0].content = propertyName;
                    }
                }
            }
            if (this.parent.diagram) {
                this.parent.diagram.dataBind();
            }
        }
    }
}