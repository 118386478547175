import { Component, ViewChild, ViewEncapsulation, OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { NodeModel } from "@syncfusion/ej2-angular-diagrams";
import { ArchimateComponent } from "../diagram/diagram.component";
interface CustomNodeModel extends NodeModel {
    addInfo?: {
        properties?: any[];
    };
}
let node: CustomNodeModel;

@Component({
    selector: 'properties',
    templateUrl: 'properties-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class PropertiesItemComponent implements OnInit {
    constructor(private parent: ArchimateComponent) { }
    ngOnInit(): void {
        if (this.properties) {
            this.rows = this.properties.addInfo.properties;
        }
    }
    @Input() properties: any;
    public rows: { name: string, value: string }[] = [];

    public selectedRowIndex: number | null = null;

    addNewRow(): void {
        this.applyAnnotationStylenotationStyle({ name: '(blank)', value: '' }, 'add', null);
    }

    removeRow(): void {
        if (this.selectedRowIndex !== null) {
            // this.rows.splice(this.selectedRowIndex, 1);
            this.applyAnnotationStylenotationStyle(null, 'remove', this.selectedRowIndex);
            this.selectedRowIndex = null; // Reset selection
        }
    }

    selectRow(index: number): void {
        this.selectedRowIndex = index;
    }

    private applyAnnotationStylenotationStyle(property: any, type: string, index: number|null): void {
        if (this.parent.diagram && this.parent.diagram.selectedItems && this.parent.diagram.selectedItems.nodes) {
            for (let i: number = 0; i < this.parent.diagram.selectedItems.nodes.length; i++) {
                if (this.parent.diagram.selectedItems.nodes[i]) {
                    node = this.parent.diagram.selectedItems.nodes[i];
                    if (node.addInfo) {
                        if(type === 'add') {
                            if (node.addInfo.properties) {
                                node.addInfo.properties.push(property);
                            }
                        }
                        else if (type === 'remove') {
                            // Filter out the property with the specified name
                            if (node.addInfo.properties) {
                                if (index !== null) {
                                    node.addInfo.properties.splice(index, 1);
                                }
                            }
                        }
                    }
                }
            }
            if (this.parent.diagram) {
                this.parent.diagram.dataBind();
            }
        }
    }
}