import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ToastComponent, ToastPositionModel } from "@syncfusion/ej2-angular-notifications";
import { throwError } from "@syncfusion/ej2-base";
import { DataTransferService } from "src/app/shared/services/data-transfer.service";
import { DataTransfersFormComponent } from "../data-transfers-form.component";
const fs = require("fs");
@Component({
    selector: 'tia',
    styleUrls: ['tia.css'],
    templateUrl: 'tia.html',
})
export class TIAComponent implements OnInit {
    public position: ToastPositionModel = { X: 'Right', Y: 'Bottom' };
    @ViewChild('toasttype')
    private toastObj: ToastComponent;
    @Input() recipient: string;
    public generalList = [];
    public specificRegulatedList = [];
    public specialCategoriesList = [];
    bpId: string;
    versionNo: any;
    constructor(private service: DataTransferService, private parent: DataTransfersFormComponent) { }
    ngOnInit(): void {
        this.bpId = sessionStorage.getItem("bpId")
        this.versionNo = JSON.parse(sessionStorage.getItem("bpListData")).filter(x => x.id == this.bpId)[0].versionNo;
    }
    public toasts: { [key: string]: Object }[] = [
        {
            title: 'Warning!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-warning', icon: 'e-warning toast-icons'
        },
        {
            title: 'Success!', content: 'There was a problem with your network connection.',
            cssClass: 'e-toast-success', icon: 'e-success toast-icons'
        }
    ];
    generateTia() {
        document.querySelector('button').disabled = true;
        this.service.generateTia(this.recipient, "Word", this.versionNo).subscribe((data: any) => {
            this.service.saveAsWordFile(data, "TIA_" + this.recipient + "_" + this.versionNo);
            document.querySelector('button').disabled = false;
        }, ((error) => {
            document.querySelector('button').disabled = false;
            this.toasts[0].content = "Data is not saved, please, try again later or contact your administrator";
            this.toastObj.show(this.toasts[0]);
            return throwError(error);
        }));
    }
}



